const overlay = document.getElementById('overlay');
const audio = document.getElementById('audio1');
const video = document.getElementById('video1');
const startButton = document.getElementById('init');
const label = document.getElementById('label');
const time = document.getElementById('time');
//const h1 = document.getElementsByTagName('h1')[0]
//const closeAbout = document.getElementById('close-about')
const intro = document.getElementById('intro');
//const about = document.getElementById('about')

var stopwatch;

var delay = 50;
var oWidth = 1280;
var oHeight = 720;

let playbackPaused = true;

document.addEventListener('DOMContentLoaded', function (/* event */) {
    if (startButton) {
        initApp();
    }
});

function initApp() {
    stopwatch = new Stopwatch(time, { delay: 100 });
    stopwatch.start();

    // window.addEventListener('load', function (/* event */) {
    /* var labelText = */ new StretchText(label);
    /* var timeText = */ new StretchText(time);

    //document.documentElement.classList.add('is-visible')
    document.documentElement.className += ' is-visible'; // Don’t use classList for better Browser fallback

    startButton.addEventListener('click', function () {
        //initAudio()
        initVideo();

        //h1.addEventListener('click', toggleAbout)
        //closeAbout.addEventListener('click', toggleAbout)

        overlay.addEventListener('mousedown', freezeTime);
        overlay.addEventListener('touchstart', freezeTime);
        overlay.addEventListener('mouseup', unfreezeTime);
        overlay.addEventListener('touchend', unfreezeTime);

        document.addEventListener(
            visibilityChange,
            handleVisibilityChange,
            false,
        );

        playbackPaused = false;
        document.documentElement.classList.toggle('is-playing');

        setTimeout(function () {
            intro.classList.remove('is-visible');
        }, 300);
    });
    // });
}

/*
function toggleAbout() {
  if (about.classList.contains('is-visible')) {
    unfreezeTime()
    playbackPaused = false
  } else {
    freezeTime()
    playbackPaused = true
  }
  about.classList.toggle('is-visible')
  document.documentElement.classList.toggle('is-playing')
}
*/

// Set the name of the hidden property and the change event for visibility
var hidden, visibilityChange;
if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
}

function handleVisibilityChange() {
    if (document[hidden]) {
        freezeTime();
    } else {
        if (!playbackPaused) {
            unfreezeTime();
        }
    }
}

function freezeTime() {
    pauseAudio(audio);
    pauseVideo(video);
    stopwatch.stop();
}

function unfreezeTime() {
    resumeAudio(audio);
    resumeVideo(video);
    stopwatch.start();
}

Date.prototype.isLeapYear = function () {
    var year = this.getFullYear();
    if ((year & 3) != 0) return false;
    return year % 100 != 0 || year % 400 == 0;
};

// Get Day of Year
Date.prototype.getDOY = function () {
    var dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
    var mn = this.getMonth();
    var dn = this.getDate();
    var dayOfYear = dayCount[mn] + dn;
    if (mn > 1 && this.isLeapYear()) dayOfYear++;
    return dayOfYear;
};

var Stopwatch = function (elem, options) {
    var timer = createTimer(),
        currentTimestamp = Date.now(),
        newTimestamp,
        offset,
        clock,
        interval;

    // default options
    options = options || {};
    options.delay = options.delay || 1;

    // append elements
    elem.appendChild(timer);

    // initialize
    reset();

    // private functions

    function createTimer() {
        return document.createElement('span');
    }

    function start() {
        if (!interval) {
            offset = Date.now();
            interval = setInterval(update, options.delay);
        }
    }

    function stop() {
        if (interval) {
            clearInterval(interval);
            interval = null;
        }
    }

    function reset() {
        clock = 0;
        render();
    }

    function update() {
        clock += delta();
        render();
    }

    function render() {
        newTimestamp = clock + currentTimestamp;
        var newDate = new Date(newTimestamp);

        var y = newDate.getFullYear();
        var d = newDate.getDOY(); // TODO: Add leading zeros
        var h = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
        var m = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
        var s = (newDate.getSeconds() < 10 ? '0' : '') + newDate.getSeconds();

        timer.textContent = y + ':' + d + ':' + h + ':' + m + ':' + s;
    }

    function delta() {
        var now = Date.now(),
            d = now - offset;

        offset = now;
        return d;
    }

    // public API
    this.start = start;
    this.stop = stop;
    this.reset = reset;
};

var StretchText = function (elem) {
    var elemW = elem.offsetWidth;
    var count = elem.textContent.length;
    var spanW;
    var charW;
    var spacing;
    var letterSpacing;

    //count = elem.textContent.length + 2 // +2 to get text centered together with padding-left on the text (below)

    //console.log(elem + " : " + elem.firstElementChild)
    var span;
    if (elem.firstElementChild === null) {
        // If element has no child, then create one
        span = document.createElement('span');
    } else {
        // Else use the first child node as wrapper
        span = elem.firstElementChild;
    }

    // Copy text of element and wrap it in a <span>
    span.textContent = elem.textContent;
    elem.textContent = '';
    elem.appendChild(span);

    spanW = span.offsetWidth;
    charW = spanW / count;

    if (spanW < elemW) {
        calc();
        window.addEventListener('resize', calc);
    }

    /*
  const calc = debounce((e) => {
    elemW = elem.offsetWidth
    spacing = elemW / count
    letterSpacing = (spacing - charW + (spacing - charW) / count).toFixed(3)
    span.style.letterSpacing = letterSpacing + 'px'
    //span.style.paddingLeft = letterSpacing + 'px' // Get text centered
  }, 250)
  window.addEventListener('resize', calc)
  */

    function calc() {
        elemW = elem.offsetWidth;
        spacing = elemW / count;
        letterSpacing = (spacing - charW + (spacing - charW) / count).toFixed(
            3,
        );
        span.style.letterSpacing = letterSpacing + 'px';
        //span.style.paddingLeft = letterSpacing + 'px' // Get text centered
    }

    // public API
    this.calc = calc;
};

function pauseAudio(audio) {
    audio.pause();
}

function resumeAudio(audio) {
    audio.play();
}

function initAudio() {
    resumeAudio(audio);
}

function pauseVideo(video) {
    video.pause();
}

function resumeVideo(video) {
    video.play();
}

function initVideo() {
    resumeVideo(video);

    // Run initAudio() with a small delay to fix FF judder
    setTimeout(function () {
        initAudio();
    }, 100);

    function maketail() {
        var dx = 1;
        copyNewContentToBuffer(dx);
        copyCurrentToBuffer(dx);
        copyBufferToTail(dx);
    }

    function convertDistToPX(dist) {
        return dist;
    }

    function copyCurrentToBuffer(d1) {
        var d2 = convertDistToPX(d1);
        var tail = document.getElementById('tail');
        var width1 = tail.width;
        var height1 = tail.height;

        var c = document.getElementById('buffer');
        var ctx = c.getContext('2d');

        //ctx.clearRect(0, 0, width1, height1)

        ctx.drawImage(
            tail,
            0,
            0,
            width1 - d2,
            height1,
            d2,
            0,
            width1 - d2,
            height1,
        );
    }

    // function copyNewContentToBuffer_1(d1) {
    //     var d2 = convertDistToPX(d1);
    //     var video1 = document.getElementById("video1");
    //     var buffer = document.getElementById("buffer");
    //     var width1 = buffer.width;
    //     var height1 = buffer.height;
    //
    //     var c = document.getElementById('buffer');
    //     var ctx = c.getContext('2d');
    //
    //     var nd2 = Math.floor(d2 * oHeight / height1);
    //     ctx.clearRect(0, 0, width1, height1);
    //
    //     ctx.drawImage(video1, oWidth - nd2, 0, nd2, oHeight, 0, 0, d2, height1);
    //     //doitMoreLight(d2)
    // }

    function copyNewContentToBuffer(d1) {
        var d2 = convertDistToPX(d1);
        var video1 = document.getElementById('video2');
        var buffer = document.getElementById('buffer');
        var width1 = buffer.width;
        var height1 = buffer.height;

        var c = document.getElementById('buffer');
        var ctx = c.getContext('2d');

        var nd2 = Math.floor((d2 * oHeight) / height1);
        ctx.clearRect(0, 0, width1, height1);

        ctx.drawImage(video1, oWidth - nd2, 0, nd2, oHeight, 0, 0, d2, height1);
        //doitMoreLight(d2)
    }

    function copyBufferToTail(/* d1 */) {
        // var d2 = convertDistToPX(d1);
        var buffer = document.getElementById('buffer');
        var width1 = buffer.width;
        var height1 = buffer.height;

        var c = document.getElementById('tail');
        var ctx = c.getContext('2d');

        //console.log(height1)

        ctx.clearRect(0, 0, width1, height1);

        ctx.drawImage(buffer, 0, 0);
    }

    function drawVideoToCanvas() {
        var video1 = document.getElementById('video1');
        var video2 = document.getElementById('video2');
        var ctx = video2.getContext('2d');
        ctx.drawImage(video1, 0, 0, oWidth, oHeight);
    }

    window.setInterval(maketail, delay);
    window.setInterval(drawVideoToCanvas, 8);
}

// function debounce(callback, wait, context = this) {
//     let timeout = null;
//     let callbackArgs = null;
//
//     const later = () => callback.apply(context, callbackArgs);
//
//     return function() {
//         callbackArgs = arguments;
//         clearTimeout(timeout);
//         timeout = setTimeout(later, wait);
//     };
// }
